"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.images = void 0;
exports.images = [
    "/image1.webp",
    "/image2.webp",
    "/image3.webp",
    "/image4.webp",
    "/image5.webp",
    "/image1.webp",
    "/image2.webp",
    "/image3.webp",
    "/image4.webp",
    "/image5.webp",
];
