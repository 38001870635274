"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("preact/jsx-runtime");
const Header_1 = require("./components/Header");
const Landing_1 = require("./components/Landing");
const Introduction_1 = require("./components/Introduction");
const Bio_1 = require("./components/Bio");
const Mission_1 = require("./components/Mission");
const Testimonials_1 = require("./components/Testimonials");
const Contact_1 = require("./components/Contact");
const Footer_1 = require("./components/Footer");
const hooks_1 = require("preact/hooks");
const motion_1 = require("motion");
const elementService_1 = require("./elementService");
function App() {
    const spotlight = (0, hooks_1.useRef)();
    (0, hooks_1.useEffect)(() => {
        (0, elementService_1.setReel)(document.querySelector(".js-reel"));
        (0, elementService_1.setScrollSections)([
            document.querySelector(".js-introduction"),
            document.querySelector(".js-bio"),
            document.querySelector(".js-mission"),
            document.querySelector(".js-testimonials"),
            document.querySelector(".js-contact"),
        ]);
        (0, elementService_1.setContact)(document.querySelector(".js-contact"));
    }, []);
    (0, hooks_1.useEffect)(() => {
        function handleMouseEvent(event) {
            spotlight.current.style.setProperty("--mouse-x", `${event.offsetX}px`);
            spotlight.current.style.setProperty("--mouse-y", `${event.offsetY}px`);
        }
        window.addEventListener("mousemove", handleMouseEvent);
    }, []);
    (0, hooks_1.useEffect)(() => {
        const bottom = document.body.scrollHeight - window.innerHeight;
        const downButton = document.querySelectorAll(".js-down-button");
        const hideDownButton = () => {
            (0, motion_1.animate)(downButton[0], { opacity: 0 });
            (0, motion_1.animate)(downButton[1], { transform: "translateY(120px)" });
        };
        const showDownButton = () => {
            (0, motion_1.animate)(downButton[0], { opacity: 1 });
            (0, motion_1.animate)(downButton[1], { transform: "translateY(0)" });
        };
        let wasBottom = false;
        window.addEventListener("scroll", () => {
            let isBottom = window.scrollY > bottom;
            if (isBottom === wasBottom) {
                return;
            }
            wasBottom = isBottom;
            if (isBottom) {
                hideDownButton();
            }
            else {
                showDownButton();
            }
        });
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { ref: spotlight, class: "spotlight" }, void 0), (0, jsx_runtime_1.jsx)(Header_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Landing_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Introduction_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Bio_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Mission_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Testimonials_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Contact_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(Footer_1.default, {}, void 0)] }, void 0));
}
exports.default = App;
