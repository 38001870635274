"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("preact/jsx-runtime");
const elementService_1 = require("../elementService");
function DownButton() {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { class: "js-down-button hidden lg:block w-[160px] h-[160px] bg-blue rounded-full filter blur-[50px] fixed right-[3px] bottom-[3px] z-10 pointer-events-none" }, void 0), (0, jsx_runtime_1.jsxs)("button", Object.assign({ onClick: handleGoDown, class: "js-down-button pointer-events-auto lg:fixed lg:right-[50px] lg:bottom-[50px] z-20 flex items-center justify-center rounded-full border-2 text-white border-white w-16 h-16 mx-auto mt-[8vh] lg:mb-0 hover:bg-white hover:text-darkPurple backdrop-blur-md hover:bg-opacity-100 bg-black bg-opacity-10" }, { children: [(0, jsx_runtime_1.jsx)("span", Object.assign({ class: "sr-only" }, { children: "Go down" }), void 0), (0, jsx_runtime_1.jsx)("svg", Object.assign({ class: "w-[20px] fill-current", viewBox: "0 0 50 40" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M 44.9844 12.9688 C 44.4531 12.9844 43.9531 13.2031 43.5859 13.5859 L 25 32.1719 L 6.41406 13.5859 C 6.03516 13.1992 5.51953 12.9805 4.97656 12.9805 C 4.16406 12.9805 3.43359 13.4766 3.12891 14.2305 C 2.82031 14.9844 3.00391 15.8477 3.58594 16.4141 L 23.5859 36.4141 C 24.3672 37.1953 25.6328 37.1953 26.4141 36.4141 L 46.4141 16.4141 C 47.0078 15.8438 47.1953 14.9648 46.875 14.2031 C 46.5586 13.4414 45.8086 12.9531 44.9844 12.9688 Z" }, void 0) }), void 0)] }), void 0)] }, void 0));
}
exports.default = DownButton;
function handleGoDown() {
    const scrollElements = (0, elementService_1.getScrollSections)();
    const currentPosition = window.scrollY;
    let nextScrollPosition = scrollElements[0].offsetTop;
    for (let i = 0; i < scrollElements.length; i++) {
        if (currentPosition >= nextScrollPosition) {
            if (scrollElements[i + 1] === undefined) {
                window.scrollTo(0, nextScrollPosition);
                return;
            }
            nextScrollPosition = scrollElements[i + 1].offsetTop;
        }
        else {
            window.scrollTo(0, nextScrollPosition);
            return;
        }
    }
}
