"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScrollSections = exports.setScrollSections = exports.getReel = exports.setReel = exports.getContact = exports.setContact = void 0;
let reel;
let contact;
let scrollSections;
function setContact(element) {
    contact = element;
}
exports.setContact = setContact;
function getContact() {
    return contact;
}
exports.getContact = getContact;
function setReel(element) {
    reel = element;
}
exports.setReel = setReel;
function getReel() {
    return reel;
}
exports.getReel = getReel;
function setScrollSections(elements) {
    scrollSections = elements;
}
exports.setScrollSections = setScrollSections;
function getScrollSections() {
    return scrollSections;
}
exports.getScrollSections = getScrollSections;
