"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("preact/jsx-runtime");
const hooks_1 = require("preact/hooks");
const imageReel_config_1 = require("../imageReel.config");
const motion_1 = require("motion");
function ImageReel() {
    const reel = (0, hooks_1.useRef)();
    (0, hooks_1.useEffect)(() => {
        const rect = reel.current.getBoundingClientRect();
        let prevScrollPos = 0;
        let currentScrollPos = 0;
        const handleReelSlide = () => {
            prevScrollPos = currentScrollPos;
            currentScrollPos = window.scrollY;
            if (0 <= rect.top && rect.top <= window.innerHeight) {
                return;
            }
            (0, motion_1.animate)(reel.current, { x: 0 }, {
                easing: (0, motion_1.glide)({
                    velocity: prevScrollPos < currentScrollPos ? -100 : 100,
                    power: 0.3,
                    decay: 0.2,
                    min: -600,
                    max: 0,
                    bounceStiffness: 1,
                }),
            });
        };
        window.addEventListener("scroll", () => handleReelSlide());
    }, [reel]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ class: "z-1 col-span-full col-start-1 mb-14 lg:mb-28 overflow-hidden" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ ref: reel, class: "js-image-reel will-transform flex flex-row z-0" }, { children: renderImages(imageReel_config_1.images) }), void 0) }), void 0));
}
exports.default = ImageReel;
function renderImages(data) {
    let imageElements = [];
    data.forEach((imageUrl) => imageElements.push((0, jsx_runtime_1.jsx)("img", { class: "image-tile ml-2", loading: "lazy", src: `./assets/images${imageUrl}`, alt: "Reel-image" }, void 0)));
    return imageElements;
}
